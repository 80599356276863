import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(private firebaseAuth: AngularFireAuth) {
    this.firebaseAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(function() {});

    this.user = firebaseAuth.authState;
    this.user.subscribe(user => {
      if (user) {
        this.userDetails = user;
      } else {
        this.userDetails = null;
      }
    });
  }

  signInWithGoogle(): Promise<firebase.auth.UserCredential> {
    return this.firebaseAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout() {
    this.firebaseAuth.auth.signOut();
  }

  get uid() {
    if (this.userDetails) {
      return this.userDetails.uid;
    }
    return null;
  }

  isLoggedIn(): Observable<boolean> {
    return this.user.pipe(
      catchError(() => {
        return of(false);
      }),
      map(user => {
        if (user) {
          return true;
        }
        return false;
      }),
    );
  }
}
