import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate() {
    return this.authService.isLoggedIn().pipe(
      tap((result: boolean) => {
        if (!result) {
          this.router.navigate(['/']);
        }
      }),
    );
  }
}
