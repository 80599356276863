import { Component, OnInit } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Innovation } from '../../models/innovation.model';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, flatMap } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { InnovationDisplay } from '../../models/innovation-display.model';
import { User } from '../../models/user.model';
import { AcceptMemberDialogComponent } from './accept-member-dialog/accept-member-dialog.component';
import { UsersService } from '../../services/users.service';
import { InnovationsService } from '../../services/innovations.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  error = false;

  innovationStream: Observable<InnovationDisplay[]>;
  user: User;

  constructor(
    private usersService: UsersService,
    private innovationsService: InnovationsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.authService.user.pipe(flatMap(user => (user ? this.usersService.getUser(user.uid) : EMPTY))).subscribe((user: User) => {
      this.user = user;

      if (!this.user) {
        this.innovationStream = EMPTY;
        return;
      }

      this.innovationStream = this.innovationsService.forUser(this.user.uid).pipe(
        map((innovations: DocumentChangeAction<Innovation>[]) => this.toInnovationDisplay(innovations)),
        flatMap((innovations: InnovationDisplay[]) => {
          if (!innovations || innovations.length === 0) {
            return EMPTY;
          }
          return of(innovations);
        })
      );
    });
  }

  acceptMember(member: User, innovation: InnovationDisplay) {
    if (!member.request) {
      return;
    }

    let teamMembers = innovation.teamMemberIds;
    const teamMemberRequests = innovation.teamMemberRequests;
    const dialogRef = this.dialog.open(AcceptMemberDialogComponent, { width: '400px', data: { id: member.uid } });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        teamMembers ? teamMembers.push(member.uid) : (teamMembers = [member.uid]);
        teamMemberRequests.splice(teamMemberRequests.indexOf(member.uid), 1);

        this.innovationsService.update(innovation.id, { teamMembers: teamMembers, teamMemberRequests: teamMemberRequests }).catch(error => {
          console.log(error);
          this.snackBar.open('Could not accept team member', null, { duration: 1000 });
        });
      }
    });
  }

  private toInnovationDisplay(innovations: DocumentChangeAction<Innovation>[]): InnovationDisplay[] {
    return innovations.map((data: DocumentChangeAction<Innovation>) => {
      const innovation: Innovation = data.payload.doc.data();
      return {
        id: data.payload.doc.id,
        name: innovation.name,
        description: innovation.description,
        user: this.usersService.getUser(innovation.user),
        members: this.usersService.valueChanges().pipe(
          map((users: User[]) => {
            const filteredUsers = users.filter((user: User) => this.isTeamMember(innovation, user));
            filteredUsers.map(user => {
              user.request = innovation.teamMemberRequests && innovation.teamMemberRequests.indexOf(user.uid) > -1;
            });
            return filteredUsers;
          })
        ),
        teamMemberRequests: innovation.teamMemberRequests,
        teamMemberIds: innovation.teamMembers,
        messages: innovation.messages
      } as InnovationDisplay;
    });
  }

  private isTeamMember(innovation: Innovation, user: User): any {
    if (innovation.teamMembers && innovation.teamMembers.indexOf(user.uid) > -1) {
      return true;
    }
    if (innovation.teamMemberRequests && innovation.teamMemberRequests.indexOf(user.uid) > -1) {
      return true;
    }

    return false;
  }
}
