import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';
import { User } from '../models/user.model';
import { Observable, timer } from 'rxjs';
import { tap, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  user: User;
  isLoading = true;

  constructor(private authService: AuthService, private userService: UsersService, private router: Router) {}

  ngOnInit() {
    this.authService.user
      .pipe(
        tap(user => {
          if (!user) {
            this.router.navigate(['']);
          }
        }),
        flatMap(user => this.userService.getUser(user.uid)),
      )
      .subscribe(user => {
        this.user = user;
        if (this.user && this.user.approved) {
          this.isLoading = false;
        }
      });

    timer(10000).subscribe(val => {
      this.isLoading = false;
    });
  }

  public navigate(page: string) {
    if (page) {
      this.router.navigate(['dashboard', page]);
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  public logout() {
    this.authService.logout();
  }
}
