import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './services/auth.service';
import { AppRoutes } from './app.routes';
import { AuthGuard } from './services/auth-guard.service';
import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HomeComponent } from './dashboard/home/home.component';
import { InnovationsComponent } from './dashboard/innovations/innovations.component';
import { TeamComponent } from './dashboard/team/team.component';
import { AddInnovationDialogComponent } from './dashboard/innovations/add-innovation-dialog/add-innovation-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteInnovationDialogComponent } from './dashboard/innovations/delete-innovation-dialog/delete-innovation-dialog.component';
import { MessagesComponent } from './dashboard/team/messages/messages.component';
import { AcceptMemberDialogComponent } from './dashboard/team/accept-member-dialog/accept-member-dialog.component';
import { UsersService } from './services/users.service';
import { InnovationsService } from './services/innovations.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    InnovationsComponent,
    TeamComponent,
    AddInnovationDialogComponent,
    DeleteInnovationDialogComponent,
    AcceptMemberDialogComponent,
    MessagesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutes,
    AngularFireModule.initializeApp(environment.firebase, 'angular-auth-firebase'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    Angular2FontawesomeModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatChipsModule,
    MatListModule,
    MatDividerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  providers: [AuthService, AuthGuard, UsersService, InnovationsService],
  bootstrap: [AppComponent],
  entryComponents: [AddInnovationDialogComponent, DeleteInnovationDialogComponent, AcceptMemberDialogComponent],
})
export class AppModule {}
