import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddInnovationDialogComponent } from '../add-innovation-dialog/add-innovation-dialog.component';

@Component({
  selector: 'app-delete-innovation-dialog',
  templateUrl: './delete-innovation-dialog.component.html',
  styleUrls: ['./delete-innovation-dialog.component.css'],
})
export class DeleteInnovationDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddInnovationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: string }) {}
  ngOnInit() {}

  onYesClick(): void {
    this.dialogRef.close({
      id: this.data.id,
    });
  }
}
