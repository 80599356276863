import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.user.subscribe(user => {
      if (user) {
        this.router.navigate(['dashboard']);
      }
    });
  }

  public signInWithGoogle() {
    this.authService.signInWithGoogle().catch(err => console.log(err));
  }
}
