import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Observable, EMPTY } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { UsersService } from '../../services/users.service';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  userStream: Observable<User>;

  constructor(private usersService: UsersService, private authService: AuthService) {}

  ngOnInit() {
    this.userStream = this.authService.user.pipe(
      flatMap(user => {
        if (!user) {
          return EMPTY;
        }
        return this.usersService.getUser(user.uid);
      }),
    );
  }

  public setUserPresent(): void {
    this.usersService.updateUser(this.authService.uid, { present: true });
  }
}
