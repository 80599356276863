import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './services/auth-guard.service';
import { HomeComponent } from './dashboard/home/home.component';
import { TeamComponent } from './dashboard/team/team.component';
import { InnovationsComponent } from './dashboard/innovations/innovations.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'innovations',
        component: InnovationsComponent,
      },
      {
        path: 'team',
        component: TeamComponent,
      },
    ],
  },
];

export const AppRoutes = RouterModule.forRoot(appRoutes);
