import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Innovation } from '../models/innovation.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { InnovationDisplay } from '../models/innovation-display.model';
import { Message } from '../models/message.model';

@Injectable({
  providedIn: 'root',
})
export class InnovationsService {
  private innovationsCollection: AngularFirestoreCollection<Innovation>;

  constructor(private afs: AngularFirestore) {
    this.innovationsCollection = afs.collection<Innovation>('innovations');
  }

  snapshotChanges(): Observable<DocumentChangeAction<Innovation>[]> {
    return this.innovationsCollection.snapshotChanges();
  }

  valueChanges(): Observable<Innovation[]> {
    return this.innovationsCollection.valueChanges();
  }

  update(id: string, value: any): Promise<any> {
    return this.innovationsCollection.doc(id).update(value);
  }

  add(value: Innovation): Promise<any> {
    return this.innovationsCollection.add(value);
  }

  delete(id: string): Promise<any> {
    return this.innovationsCollection.doc(id).delete();
  }

  forUser(user: string): Observable<DocumentChangeAction<Innovation>[]> {
    const myInnovationsCollection = this.afs.collection<Innovation>('innovations', ref => ref.where('user', '==', user));
    const joinedInnovationsCollection = this.afs.collection<Innovation>('innovations', ref => ref.where('teamMembers', 'array-contains', user));

    return myInnovationsCollection.snapshotChanges().pipe(
      switchMap((myInnovations: DocumentChangeAction<Innovation>[]) => {
        return joinedInnovationsCollection.snapshotChanges().pipe(
          map((joinedInnovations: DocumentChangeAction<Innovation>[]) => {
            return myInnovations.concat(joinedInnovations);
          }),
        );
      }),
    );
  }

  getMessages(id: string): Observable<Message[]> {
    return this.innovationsCollection
      .doc(id)
      .collection<Message>('messages')
      .valueChanges();
  }

  addMessage(id: string, value: Message): any {
    return this.innovationsCollection
      .doc(id)
      .collection<Message>('messages')
      .add(value);
  }
}
