import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-accept-member-dialog',
  templateUrl: './accept-member-dialog.component.html',
  styleUrls: ['./accept-member-dialog.component.css'],
})
export class AcceptMemberDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AcceptMemberDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { id: string }) {}
  ngOnInit() {}

  onYesClick(): void {
    this.dialogRef.close({
      id: this.data.id,
    });
  }
}
