import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  usersCollection: AngularFirestoreCollection<User>;

  constructor(private afs: AngularFirestore) {
    this.usersCollection = afs.collection<User>('users');
  }

  snapshotChanges(): Observable<DocumentChangeAction<User>[]> {
    return this.usersCollection.snapshotChanges();
  }

  valueChanges(): Observable<User[]> {
    return this.usersCollection.valueChanges();
  }

  getUser(uid: string): Observable<User> {
    return this.usersCollection.doc<User>(uid).valueChanges();
  }

  updateUser(uid: string, value: any): Promise<any> {
    return this.usersCollection.doc<User>(uid).update(value);
  }
}
