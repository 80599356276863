import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Message } from '../../../models/message.model';
import { firestore } from 'firebase/app';
import { InnovationDisplay } from '../../../models/innovation-display.model';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../../models/user.model';
import { InnovationsService } from '../../../services/innovations.service';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  newMessage = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);

  @Input()
  innovation: InnovationDisplay;

  @Input()
  user: User;

  messagesStream: Observable<Message[]>;

  constructor(private innovationsService: InnovationsService, private usersService: UsersService) {}

  ngOnInit() {
    this.messagesStream = combineLatest(this.innovationsService.getMessages(this.innovation.id), this.usersService.valueChanges()).pipe(
      map((result: [Message[], User[]]) => {
        if (!result[0]) {
          return result[0];
        }
        return result[0]
          .map((message: Message) => {
            return Object.assign({}, message, { user: result[1].find((user: User) => user.uid === message.user) });
          })
          .sort((message1: Message, message2: Message) => {
            if (!message1.timestamp) {
              return 1;
            }
            if (!message2.timestamp) {
              return -1;
            }
            return message1.timestamp < message2.timestamp ? 1 : -1;
          });
      }),
    );
  }

  sendMessage(): void {
    if (this.newMessage.valid) {
      this.innovationsService.addMessage(this.innovation.id, {
        text: this.newMessage.value,
        user: this.user.uid,
        timestamp: firestore.FieldValue.serverTimestamp(),
      } as Message);
      this.newMessage.reset();
    }
  }
}
