import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Innovation } from '../../../models/innovation.model';
import { FormControl, Validators } from '@angular/forms';
import {} from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-add-innovation-dialog',
  templateUrl: './add-innovation-dialog.component.html',
  styleUrls: ['./add-innovation-dialog.component.css']
})
export class AddInnovationDialogComponent implements OnInit {
  name = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]);
  description = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(1000)]);
  helpRequired = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(150)]);

  constructor(
    public dialogRef: MatDialogRef<AddInnovationDialogComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; value: Innovation }
  ) {
    if (data.value) {
      this.name = new FormControl(data.value.name, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]);
      this.description = new FormControl(data.value.description, [Validators.required, Validators.minLength(5), Validators.maxLength(1000)]);
      this.helpRequired = new FormControl(data.value.helpRequired, [Validators.required, Validators.minLength(5), Validators.maxLength(150)]);
    }
  }

  ngOnInit() {}

  onYesClick(): void {
    if (this.name.valid && this.description.valid && this.helpRequired.valid) {
      const innovation: Innovation = {
        name: this.name.value,
        description: this.description.value,
        helpRequired: this.helpRequired.value,
        user: this.authService.uid
      };
      this.dialogRef.close({
        id: this.data.id,
        value: innovation
      });
    }
  }

  getNameErrorMessage() {
    if (this.name.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.name.hasError('minlength')) {
      return 'Enter at least 5 characters';
    }
    if (this.description.hasError('maxlength')) {
      return 'Enter at most 50 characters';
    }
    return '';
  }

  getDescriptionErrorMessage() {
    if (this.description.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.description.hasError('minlength')) {
      return 'Enter at least 5 characters';
    }
    if (this.description.hasError('maxlength')) {
      return 'Enter at most 1000 characters';
    }
    return '';
  }

  getHelpRequiredErrorMessage() {
    if (this.description.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.description.hasError('minlength')) {
      return 'Enter at least 5 characters';
    }
    if (this.description.hasError('maxlength')) {
      return 'Enter at most 150 characters';
    }
    return '';
  }
}
