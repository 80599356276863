import { Component, OnInit } from '@angular/core';
import { DocumentChangeAction } from '@angular/fire/firestore';
import { Observable, combineLatest, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Innovation } from '../../models/innovation.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddInnovationDialogComponent } from './add-innovation-dialog/add-innovation-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthService } from '../../services/auth.service';
import { InnovationDisplay } from '../../models/innovation-display.model';
import { DeleteInnovationDialogComponent } from './delete-innovation-dialog/delete-innovation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../models/user.model';
import { UsersService } from '../../services/users.service';
import { InnovationsService } from '../../services/innovations.service';

@Component({
  selector: 'app-innovations',
  templateUrl: './innovations.component.html',
  styleUrls: ['./innovations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class InnovationsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'user'];
  innovationsStream: Observable<MatTableDataSource<InnovationDisplay>>;
  expandedElement: string;
  error = false;

  private filter: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private usersService: UsersService,
    private innovationsService: InnovationsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.innovationsStream = combineLatest(this.innovationsService.snapshotChanges(), this.filter.asObservable()).pipe(
      map((result: [DocumentChangeAction<Innovation>[], string]) => {
        const innovationDisplays = result[0].map((data: DocumentChangeAction<Innovation>) => {
          const innovation: Innovation = data.payload.doc.data();
          return {
            id: data.payload.doc.id,
            name: innovation.name,
            description: innovation.description,
            helpRequired: innovation.helpRequired,
            user: this.usersService.getUser(innovation.user),
            teamMemberRequests: innovation.teamMemberRequests,
            teamMemberIds: innovation.teamMembers
          } as InnovationDisplay;
        });

        const dataSource = new MatTableDataSource(innovationDisplays);
        dataSource.filter = result[1];
        this.error = false;
        return dataSource;
      }),
      catchError(error => {
        this.error = true;
        return throwError(error);
      })
    );

    this.filter.next('');
  }

  applyFilter(filterValue: string) {
    this.filter.next(filterValue);
  }

  addInnovation() {
    this.editInnovation(null, null);
  }

  editInnovation(id: string, innovation: Innovation) {
    const dialogRef = this.dialog.open(AddInnovationDialogComponent, {
      width: '500px',
      data: { id: id, value: innovation }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.id) {
          this.innovationsService.update(result.id, result.value).catch(error => {
            console.log(error);
            this.snackBar.open('Could not update innovation', null, { duration: 1000 });
          });
        } else {
          this.innovationsService.add(result.value).catch(error => {
            console.log(error);
            this.snackBar.open('Could not add innovation', null, { duration: 1000 });
          });
        }
      }
    });
  }

  deleteInnovation(id: string) {
    const dialogRef = this.dialog.open(DeleteInnovationDialogComponent, {
      width: '400px',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.innovationsService.delete(result.id).catch(() => {
          this.snackBar.open('Could not delete innovation', null, { duration: 1000 });
        });
      }
    });
  }

  requestJoin(innovation: InnovationDisplay): void {
    innovation.teamMemberRequests.push(this.authService.uid);
    this.innovationsService.update(innovation.id, { teamMemberRequests: innovation.teamMemberRequests });
  }

  isNotTeamMember(innovation: InnovationDisplay): boolean {
    if (innovation.teamMemberIds && innovation.teamMemberIds.find(user => user === this.authService.uid)) {
      return false;
    }
    if (innovation.teamMemberRequests && innovation.teamMemberRequests.find(user => user === this.authService.uid)) {
      return false;
    }
    return true;
  }

  isCurrentUsersInnovation(user: User): boolean {
    if (!user) {
      return false;
    }

    return this.authService.uid === user.uid;
  }

  switchRow(newExpandElement: string) {
    if (this.expandedElement !== newExpandElement) {
      this.expandedElement = newExpandElement;
    } else {
      this.expandedElement = null;
    }
  }
}
